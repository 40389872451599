:root {
  --color-main: #2f2f40;
  --color-special: #CF962E;
  --color-special-hover: #DAAA59;
  --color-special-shadow: rgba(255, 51, 103, 0.3);
  --color-special2: #E4DAD2;
  --color-special2-hover: #EAE1DB;
  --color-special2-shadow: rgba(0, 245, 188, 0.5);
  --color-special2-dark: #048c6c;
  --color-white-shadow: rgba(255, 255, 255, 0.3);
  --color-light-blue: #ebf0f8;
  --color-dark: #4A2D22;
  --color-darker: #353548;
  --color-darkest: #001235;
  --color-gray: #999999;
  --color-light: #f0f0f0;
  --header-height: 95px;
  --color-border: #cccccc;
  --shadow-width: 3px;
  --wrap-width: 1160px;
  --wrap-wide: 1265px;
  --blocks-padding: 50px;
  --blocks-padding-sm: 15px;
  --color-plum: #4A2D22;
  --color-plum-dark: #4A2D22;
}

.app-container {
  font-family: "Arial", sans-serif;
  background: white;
  min-height: 100vh;
  height: 100vh;
  padding: 0 !important;
}

.app-spinner {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.app-spinner .spinner-border {
  height: 100px;
  width: 100px;
}

.app-topbar {
  background: white;
  height: 70px;
}

.topbar-container {
  padding: 0 100px;
  width: 100%;
}

.app-topbar img {
  height: 60px;
}

/********************* COMMON COMPONENTS **********************/
.common-button {
  background-color: #4e73df !important;
  border: 1px solid #4e73df !important;
}

.common-button:hover {
  background: #2e59d9 !important;
  border: 1px solid #2653d4 !important;
}

.common-form-control {
  border: none !important;
  background: #f8f9fc !important;
  color: #858796 !important;
}

.common-form-control::placeholder {
  color: #858796 !important;
}

.common-right-justified {
  justify-content: flex-end;
}

.common-center-justified {
  justify-content: center;
}

.regBtnCol {
  /* justify-content: flex-end;
  display: flex;
  flex-direction: column; */
  /* margin-bottom: 64px; */
}

.regBtnRow {
  justify-content: flex-end;
  /* height: 40%; */
  height: 35%;
}

.btnClearSpecial {
  background: transparent !important;
  color: var(--color-dark) !important;
  border: 2px solid var(--color-special2) !important;
  font-weight: bold !important;
  font-size: 22px !important;
}

.btnClearSpecial:hover {
  background: var(--color-special2-hover) !important;
  border: 2px solid var(--color-special2-hover) !important;
  /* color: white !important; */
}

.topRightBtn {
  position: absolute;
  top: 25px;
  right: 25px;
}

.btnSize1 {
  width: 125px;
  height: 40px;
}

.btnSize2 {
  width: 345px;
  /* max-width: 100% !important; */
}

.btnSize3 {
  font-size: 14px !important;
  width: 125px;
  height: 40px;
}

.btnSizeFull {
  width: 100%;
  height: 52px;
}

.extra-bottom-padding {
  /* padding-bottom: 16px; */
}

.marg_t2 {
  margin: 40px 0 0 0;
  padding: 0 0 25px 0;
}

.marg_t3 {
  margin: 30px;
}

.transparent-teal-button {
  color: #CF962E !important;
  border: 1px solid #CF962E !important;
  background: white !important;
}

.transparent-teal-button:hover {
  background: #DAAA59 !important;
  border: 1px solid #DAAA59 !important;
  color: black !important;
}

.teal-button {
  background-color: var(--color-special) !important;
  border: 1px solid var(--color-special) !important;
  padding: 0.375rem 0.75rem !important;
  border-radius: 0.35rem !important;
}

.teal-button:hover {
  background: var(--color-special-hover) !important;
  border: 1px solid var(--color-special-hover) !important;
  /* color: black !important; */
}

.special-button {
  color: var(--color-dark) !important;
  background-color: var(--color-special2) !important;
  border: 1px solid var(--color-special2) !important;
  padding: 0.375rem 0.75rem !important;
  border-radius: 0.35rem !important;
}

.special-button:hover {
  background: var(--color-special2-hover) !important;
  border: 1px solid var(--color-special2-hover) !important;
  /* color: black !important; */
}

.xs-button {
  padding: 4px 8px !important;
  font-size: 12px !important;
  line-height: 4px !important;

  height: 20px !important;
}

.delete-button {
  background-color: Tomato !important;
  border: 1px solid Tomato !important;
  border-radius: 0.35rem !important;
  padding: 0.375rem 0.75rem !important;
}

.delete-button:hover {
  background-color: FireBrick !important;
  border: 1px solid FireBrick !important;
  color: black !important;
}

.common-checkbox {
  width: max-content !important;
}

.common-grey-spinner {
  color: #858796 !important;
}

.teal-spinner {
  color: var(--color-special2-dark);
}

.common-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
}

.common-modal .modal-content {
  padding: 0 10px;
  border: none;
  border-radius: 2px;
}

.common-modal .modal-body {
}

.delete-modal-body {
  padding: 16px;
}

.delete-modal-body .text-row {
  justify-content: center;
  margin-bottom: 8px;
}

.delete-modal-body .admin-name-row {
  justify-content: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}

.delete-modal-body .button-row {
  justify-content: center;
}

.delete-modal-body .button-row .delete-button {
  margin-right: 8px;
}

.common-admin-form-control {
  color: #858796 !important;
  margin: 0 !important;
}

.common-date-picker {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-left: 6px;
  height: 31px;
  background: white;
}

.common-date-picker.invalid {
  border: 1px solid red;
}

.common-date-picker .react-date-picker__wrapper {
  border: none;
  border-radius: 2px;
}

.common-date-picker .react-date-picker__inputGroup input {
  color: #858796 !important;
}

.common-date-picker .react-date-picker__clear-button svg,
.common-date-picker .react-date-picker__calendar-button svg {
  stroke: #858796 !important;
}

.common-date-picker .react-date-picker__clear-button svg:hover,
.common-date-picker .react-date-picker__calendar-button svg:hover {
  stroke: black !important;
}

.common-date-picker .react-calendar {
  position: absolute;
  z-index: 100 !important;
}

.form-saving-text {
  position: absolute;
  bottom: 8px;
  right: 0;
}

.form-saving-text-forms {
  position: absolute;
  bottom: 8px;
  right: 150px;
}

.form-saving-text .spinner-border {
  margin-right: 6px;
}

.common-pagination .page-item a {
  border: 1px solid transparent;
  color: #CF962E;
}

.common-pagination .page-item.active span {
  background: #CF962E;
  border: 1px solid #CF962E;
}

.common-pagination .page-item.active span:hover {
  cursor: pointer;
}

.pagination-row {
  justify-content: center;
}

.pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

.pagination ul {
  list-style-type: none;
}

.pagination__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.pagination__item {
  cursor: pointer;
  align-items: center;
  display: flex;
  text-align: center;
  height: 32px !important;
  width: 32px !important;
  color: #CF962E;
}

.pagination__item:hover {
  text-decoration: underline;
}

.pagination__link {
  border-radius: 3px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination__link--active {
  background: #CF962E;
  color: white !important;
}

.pagination__arrow {
  position: relative;
  width: 32px;
  height: 32px;
  color: #CF962E !important;
}

.pagination__arrow.disabled {
  opacity: 0.5;
  cursor: default;
}

.pagination__icon {
  display: inline-block;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination__icon::after {
  content: "";
  position: absolute;
  top: 12px;
  height: 10px;
  width: 10px;
}

.pagination__icon--prev::after {
  /* left: 15px; */
  /* transform: rotate(135deg); */
}

.pagination__icon--next::after {
  /* left: 9px;
  transform: rotate(-45deg); */
}

.pagination__ellipsis {
  position: relative;
  width: 32px;
  height: 32px;
  color: #CF962E;
}

.pagination__ellipsis-link {
  font-size: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  user-select: none;
}

.admin-tabs .nav-item {
  color: #858796;
  font-size: 14px;
  padding: 4px 8px;
}

.admin-tab {
  padding: 8px;
  min-height: 300px;
  max-height: none;
}

.admin-tabs .active {
  color: #858796 !important;
}

.common-admin-formControl {
  color: #858796 !important;
}

.admin-tabs .tab-content {
}

.spaced-admin-tabs {
  padding-bottom: 35px;
}

.in-button-spinner {
  font-size: 8px !important;
  height: 16px !important;
  width: 16px !important;
}

.td-row {
  justify-content: space-between;
}

.system-form-control-error-text {
  color: crimson;
  font-size: 12px;
  padding: 4px;
}

#formControl-error {
  border: 1px solid crimson;
}

.password-format-icon {
  position: absolute;
  right: 0;
  top: 0;
}

.password-toggle-icon {
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 40px;
  color: var(--color-main);
}

.password-toggle-icon#on {
  opacity: 1;
}

.password-toggle-icon#off {
  opacity: 0.2;
}

.login-form-div {
  position: relative;
}

.signup-btn {
  /* position: absolute; */
  /* top: 25px; */
  /* right: 25px; */
}

.height-auto {
  height: auto !important;
}

/********************* END OF COMMON COMPONENTS ***************/

/********************* LOGIN COMPONENT ************************/
.login-small-logo {
  height: 30px;
  position: absolute;
  top: 24px;
  left: 16px;
}

.logout-btn-top-right {
  background-color: var(--color-special) !important;
  border-color: var(--color-special) !important;
  position: absolute;
  right: 16px;
  top: 24px;
}

.login-small-logo-top {
  height: 30px;
}

.login-container {
  background-color: white;
  /* align-items: center; */
  height: 100% !important;
}

.login-container-top-row {
  justify-content: space-between;
  align-items: center;
  height: 95px;
  padding: 0 16px;
  width: 100%;
  top: 0;
}

body:not(.home) #header {
  display: flex;
}

.content-div #content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 1 1 auto;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  padding: 0px 0 0 0;
}

.login-row {
  /* height: 50%; */
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
}

.login-logo-row {
  background: #5a5c69;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  /* height: 90px; */
}

.login-logo {
  max-height: 8rem;
}

.login-logo-box {
  padding: 16px 0 16px 70px;
}

.register-box {
  background: white;
  height: 400px;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 20px;
  position: relative;
}

.login-box {
  border: none !important;
  width: 380px;
}

.registration-box {
  width: 380px;
  padding: 16px;
  height: max-content;
  border: none !important;
}

.login-box .row {
  height: 100%;
}

.login-content-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.login-content-col h4 {
  color: #858796;
  height: max-content;
}

.login-content-col button {
  margin-top: 20px;
}

.login-content-col .row {
  justify-content: space-around;
  height: max-content;
}

.login-navlinks {
  /* position: absolute; */
  width: 100%;
  margin-top: 32px;
  justify-content: space-between;
  display: flex;
}

.login-navlinks a {
  color: #4e73df;
}

.login-navlinks a:hover {
  color: #2e59d9;
}

.login-formcontrol-row {
  justify-content: space-between !important;
  margin-bottom: 10px;
  color: #858796;
  align-items: center;
}

.login-formcontrol-row .form-control {
  width: 70%;
}

.login-header {
  padding: 3rem !important;
  background: #5a5c69;
}

.login-box-padding {
  padding: 0 3rem 0 3rem;
}

.login-welcome .col {
  padding: 15px;
}

.login-box-col {
  padding: 0px !important;
}

.login-box-col h1 {
  margin-bottom: 30px;
  color: var(--color-dark) !important;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}

.login-h1 {
  /* margin-bottom: 30px; */
  color: black !important;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  padding-bottom: 5px;
}

.login-p {
  text-align: center;
  font-size: 14px;
}

.login-formcontrol {
  width: 100% !important;
  border: none !important;
  background: #f0f0f0 !important;
  margin-bottom: 16px;
  padding: 0 20px !important;
  height: 50px !important;
  color: var(--color-main) !important;
}

.login-formcontrol .error {
  border-color: red !important;
  color: red !important;
}

.login-formcontrol.password-toggler {
  padding-right: 50px !important;
}

.login-formcontrol-container {
  margin-bottom: 16px;
}

.login-formcontrol .PhoneInputInput {
  background: transparent;
  border: none;
  color: var(--color-main) !important;
  outline: 0;
}

.login-formcontrol-error {
  width: 100% !important;
  border: 1px solid red;
  border-radius: 3px;
  background: #f0f0f0 !important;
  margin-bottom: 16px;
  padding: 0 20px !important;
  height: 50px !important;
  color: var(--color-main) !important;
}

.login-formcontrol-error .PhoneInputInput {
  border: none;
  color: red !important;
  background: transparent;
  outline: 0;
}

.login-button-row {
  justify-content: flex-end !important;
  margin-bottom: 32px;
}

.login-button-row .teal-button {
  width: 100%;
  height: 52px;
}

/********************* END OF LOGIN COMPONENT *****************/

/********************* REGISTER COMPONENT *********************/
.register-navlink {
  margin-top: 32px;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #858796;
}

.register-navlink a {
  color: #4e73df;
  margin-left: 10px;
}

.register-navlink a:hover {
  color: #2e59d9;
}

.registration-not-100-height {
}

.registration-current-section-loading-container {
  height: 800px;
  max-height: 100%;
}

/********************* END OF REGISTER COMPONENT **************/

/********************* FORGOT COMPONENT *********************/
.forgot-header {
  color: #858796;
  margin-bottom: 40px;
  padding: 0 60px;
  text-align: center;
}

.request-success-col {
  color: #858796;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.request-success-col .row {
  height: 100% !important;
  align-items: center;
}

/********************* END OF FORGOT COMPONENT **************/

/********************* CONFIRM EMAIL COMPONENT *********************/
.confirmation-body {
  text-align: center;
}

.confirmation-footer {
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.resend-confirmation {
  text-decoration: underline !important;
  color: #4e73df !important;
  cursor: pointer !important;
}

.resend-confirmation:hover {
  color: #2e59d9 !important;
}
/********************* END OF CONFIRM EMAIL COMPONENT **************/

/********************* ADMIN COMPONENT *********************/
.admin-container {
  height: 100%;
  padding: 0 !important;
}

.admin-container .row {
  height: 100%;
}

.admin-sidebar#collapsed-sidebar {
  width: 6.5rem !important;
}

.admin-sidebar#collapsed-sidebar .admin-logo {
  /* width: 70px; */
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title {
  font-size: 12px;
  text-align: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-title .row {
  justify-content: center;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion .row {
  justify-content: center;
  font-size: 12px;
}

.admin-sidebar#collapsed-sidebar .admin-list-item-icon {
  font-size: 16px !important;
}

.admin-sidebar-collapse-toggle-row {
  justify-content: center;
}

.admin-sidebar-collapse-toggle-aura {
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.admin-sidebar-collapse-toggle-aura:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}

.admin-sidebar-bottom {
  position: absolute;
  bottom: 16px;
  align-items: center;
  width: 224px;
  justify-content: center;
  display: flex;
}

.admin-sidebar-bottom .admin-sidebar-logout-icon {
  margin-right: 6px;
  color: #858796;
}

.admin-sidebar-bottom:hover {
  cursor: pointer;
}

.admin-sidebar-bottom:hover .admin-sidebar-logout-icon {
  color: white;
}

.reg-sidebar {
  background: #0d0d0d;
  width: 224px;
  color: white;
  /* position: relative; */
  z-index: 4 !important;
  /* max-height: 100%; */
}

.reg-sidebar .col {
  padding: 16px;
}

.admin-sidebar {
  background: #0d0d0d;
  width: 224px;
  color: white;
  position: relative;
  z-index: 4 !important;
}

.admin-sidebar .col {
  padding: 16px;
}

.admin-logo {
  /* width: 100%; */
  margin-bottom: 5px;
  /* width: 192px; */
  width: 100%;
}

.admin-logo-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 16px;
  justify-content: center;
  padding-bottom: 16px;
}

.admin-logo-row.new-col {
  border-bottom: 1px solid var(--color-border);
}

.system-header {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.65rem;
  font-weight: 800;
}

.admin-topbar-navdropdown {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 70px;
  width: 200px;
  border-top: 8px solid DarkTurquoise;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropdown a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #858796;
}

.admin-topbar-navdropdown a:hover {
  color: black;
}

.admin-topbar-navdropdown .dropdown-toggle::after {
  color: DarkTurquoise !important;
}

.admin-topbar-navdropdown .dropdown-menu {
  border: none;
  padding: 0;
  margin: 0;
  width: 200px;
  border-radius: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin-topbar-navdropitem {
  height: 70px !important;
  width: 200px !important;
}

.admin-topbar-navdropitem {
  border-top: 8px solid #858796 !important;
}

.admin-topbar-navdropitem:first-child {
  border-top: 8px solid BlueViolet !important;
}

.admin-topbar {
  background: white;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  height: 4.375rem;
  padding: 8px 24px;
  z-index: 100;
  position: relative;
}

.admin-topbar-nav {
  font-size: 13px;
  position: absolute !important;
  top: 0 !important;
  right: 24px;
  height: 70px;
  padding: 0 12px;
}

.admin-topbar-nav a {
}

.admin-topbar-nav:hover {
  color: #b7b9cc !important;
}

.admin-topbar-nav .dropdown-toggle {
  padding: 0;
}

.admin-topbar-nav .dropdown-menu {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px;
}

.admin-topbar-nav .dropdown-item {
  color: black !important;
}

.admin-topbar-nav .dropdown-item svg {
  color: #d1d3e2;
  margin: 0;
  margin-right: 10px;
}

.admin-topbar-nav .dropdown-toggle::after {
  color: transparent;
}

.admin-topbar-nav a {
  color: #858796;
  align-items: center;
  display: flex;
  height: 100%;
}

.admin-topbar-nav a:hover {
  color: #b7b9cc;
}

.admin-topbar-nav .dropdown-item:active {
  background: rgba(0, 0, 0, 0.1);
}

.admin-topbar-nav a svg {
  margin-left: 8px;
  font-size: 14px;
}

.admin-topbar-nav a::after {
  margin-left: 8px;
}

.admin-topbar-row {
  justify-content: space-between;
  align-items: center;
}

.admin-topbar .form-control {
  width: 300px;
}

.admin-search-container {
  display: flex;
  width: 50%;
  align-items: center;
  margin-left: 1rem;
}

.admin-list-group .active {
  /* background: rgba(255, 255, 255, 0.4) !important; */
}

.admin-list-item-direction-arrow {
  /* position: absolute; */
  right: 0;
  top: 16px;
}

.admin-list-accordion button {
  background: transparent !important;
  color: white !important;
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  text-align: center;
}

.admin-list-accordion-collapse {
  border: none !important;
  background: rgba(255, 255, 255, 0.3) !important;
  border-radius: 3px;
  padding: 8px 0;
  margin-top: 16px;
  margin-bottom: 16px !important;
  position: relative !important;
  width: 100%;
  font-size: 14px !important;
  /* margin-top: 8px; */
}

.admin-list-accordion-collapse ul {
  list-style: none;
  padding: 0;
  margin: 0 8px;
}

.admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.admin-list-accordion-collapse ul li:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.admin-list-item {
  font-size: 0.85rem;
  color: white;
  background: transparent !important;
  /* padding: 16px; */
  padding: 16px 0 !important;
  border: none !important;
}

.admin-list-item:hover {
  cursor: pointer;
}

.admin-list-item:hover .admin-list-item-icon {
  color: white;
}

.admin-list-item .row {
  align-items: center;
}

.admin-list-item-icon {
  color: rgba(255, 255, 255, 0.4);
  margin-right: 5px;
}

.admin-list-item-title {
  color: white;
  border: none;
  padding: 0 !important;
}

.admin-list-item-title:hover {
  color: white;
  text-decoration: none;
}

.admin-content-pane {
  height: calc(100vh - 70px);
  overflow-y: scroll;
  z-index: 2;
  position: relative;
  background: white !important;
}

.admin-content-container {
  padding: 24px !important;
  /* height: calc(100% - 70px) !important; */
}

/********************* END OF ADMIN COMPONENT **************/

/********************* DASHBOARD COMPONENT *********************/
.dashboard-container {
  height: 100%;
  padding: 24px !important;
}

.dashboard-section-box {
  background: white;
  padding: 8px 16px;
  color: #858796;
  margin-bottom: 16px;
  height: max-content;
  width: 100%;
}

.dashboard-section-box.full-length {
  /* padding-right: 48px; */
}

.dashboard-section-box .header-div {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 8px;
}

.dashboard-section-box h4 {
  margin: 0;
}

.dashboard-total {
  margin-bottom: 16px;
  font-size: 14px;
}

.dashboard-chart-row {
  margin-bottom: 16px;
}

.chart-container {
  width: 49%;
  height: max-content;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  padding: 8px;
}

.chart-container:not(:last-child) {
  margin-right: 16px;
}

.chart-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 8px;
}

.chart-box {
  height: 250px;
  width: 100%;
}

.line-chart-tooltip {
  background: white;
  padding: 4px 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  box-shadow: 3px;
  font-size: 14px;
}

.pie-chart-tooltip {
  font-size: 14px;
  padding: 0;
}

/********************* END OF DASHBOARD COMPONENT **************/

/********************* FORMS COMPONENT *********************/

.forms-container {
  height: 100vh;
  overflow-y: scroll;
  z-index: 50;
  position: relative;
  justify-content: center;
  display: flex;
  color: #858796;
  padding: 24px;
}

.forms-header {
  height: max-content !important;
  color: #858796;
  margin-bottom: 24px;
  align-items: center;
}

.forms-search .col-sm-4 {
  margin-right: 20px;
}

.form-search-form-row {
  padding-right: 200px;
}

.form-formControl-container {
  flex-direction: column;
  align-items: flex-start !important;
  margin-right: 16px;
}

.form-list-label {
  margin: 0;
}

.form-list-formControl {
  margin: 0 !important;
  width: 211px !important;
}

.form-button-container {
  /* position: absolute;
  top: 38px;
  right: 20px; */
  align-items: center;
  display: flex;
  flex-direction: row;
}

.form-button-container .preview-button {
  margin-right: 4px;
}

.form-search-container {
  display: flex;
  align-items: center;
  color: #858796;
}

.form-search-container .form-control {
  margin-left: 10px;
  background: white;
  border: 1px solid #d1d3e2;
  color: #858796 !important;
}

.form-dropdown {
  color: #858796 !important;
}

.forms-table-box {
  background: white;
  font-size: 13px;
  padding: 20px;
  position: relative;
  z-index: 3;
}

.forms-search {
  margin-bottom: 16px;
}

.forms-create-button-row {
  justify-content: center;
  padding-top: 16px;
}

.forms-table-row {
}

.forms-table {
}

.forms-table tr {
  color: #858796;
}

.forms-table th {
  width: 50%;
}

.individual-form-status-row {
  justify-content: space-between;
}

.individual-form-icon:not(:last-child) {
  margin-right: 8px;
}

.individual-form-icon {
  font-size: 16px;
  color: #858796;
}

.individual-form-icon:hover {
  color: black;
  cursor: pointer;
}

.individual-form-icon-disabled {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
}

.individual-form-icon.up-button {
  margin-right: 0;
}

.edit-forms-header h4 {
  align-items: center;
  display: flex;
}

.edit-forms-header:hover {
  cursor: pointer;
  color: black;
}

.edit-form-name {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  justify-content: space-between;
}

.edit-form-name div {
  /* display: flex;
  align-items: center; */
}

.edit-form-name .form-control {
  width: 300px;
}

.add-fields-button-row {
  justify-content: center;
  padding-top: 16px;
}

.individual-field {
  margin-top: 16px;
  color: #858796;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 16px;
}

.individual-field .form-control {
  color: #858796;
}

.individual-field .form-control:focus {
  color: #858796;
}

.individual-field-required-row div {
  display: flex;
  align-items: center;
}

.form-saving-new-text {
  margin-right: 16px;
}

.form-saving-new-text .spinner-border {
  margin-right: 4px;
}

.individual-field-row {
  height: max-content !important;
}

.individual-field-sub-column {
  margin-right: 16px;
}

.individual-field-label {
  margin: 0;
  /* margin-top: 8px; */
}

.individual-field-buttons-column {
  margin-left: 16px;
}

.individual-form-buttons-row {
  align-items: center;
  justify-content: flex-end;
  height: 58px !important;
  margin-top: 4px;
}

.individual-form-buttons-row button {
  margin-left: 4px;
}

.individual-field-buttons-row {
  align-items: center;
  justify-content: space-between;
  height: 58px !important;
  /* margin-top: 16px; */
  margin-top: 4px;
}

.empty-dropdownLink {
  width: 79.13px;
}

.empty-subfield-dropdownLink {
  width: 70.13px;
}

.required-box {
  display: flex;
  align-items: center;
}

.required-box label {
  margin: 0;
  margin-right: 8px;
}

.field-description-accordion {
  /* background: red; */
}

.field-description-accordion .form-control::placeholder {
  color: #a8aaba;
}

.field-description-accordion button {
  background: transparent;
  border: none;
  color: #858796;
  padding: 0;
  margin-top: 8px;
  outline: none !important;
}

.field-description-accordion button:hover {
  cursor: pointer;
  text-decoration: underline;
  color: black;
}

.field-description-react-quill .ql-toolbar {
  padding: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.field-description-react-quill .ql-toolbar button {
  margin: 0;
}

.field-description-react-quill .ql-container {
  border: none;
  /* overflow-y: scroll; */
}

.field-description-react-quill .ql-editor {
  max-height: 300px;
}

.field-description-react-quill .ql-editor {
  border: 1px solid #ced4da;
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 4px 8px;
  font-family: "Nunito", sans-serif;
  min-height: 50px;
}

.field-description-react-quill .ql-tooltip {
  left: 0 !important;
  color: #858796;
}

.field-description-react-quill .ql-tooltip input {
  color: #858796;
  border-radius: 3px;
}

.disabled-react-quill {
  background: #e9ecef;
  width: 100%;
  min-height: 50px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 4px 8px;
}

.no-fields {
  color: #858796;
  justify-content: center;
  display: flex;
  padding: 20px;
}

.form-publish-button-column {
  justify-content: flex-end;
}

.form-publish-button-column .teal-button {
  margin-left: 16px;
}

.form-publish-button-column .spinner-border {
  margin-right: 6px;
  color: #858796 !important;
}

.no-forms-table {
}

.no-forms-error {
  color: red;
}

.individual-dropdown-option {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.individual-dropdown-option .form-control {
  width: 50%;
  color: #858796 !important;
}

.individual-dropdown-option .form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.individual-subfield-container {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.individual-subfield-option {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.individual-subfield-option .form-control {
  width: 50%;
  color: #858796 !important;
}

.individual-subfield-option .form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.individual-subfield {
  align-items: center;
  width: 100%;
}

.individual-subfield-buttons-row {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.individual-subfields-data {
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.05);
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.individual-subfields-header {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.individual-branch-label {
  font-size: 14px;
  margin: 0;
}

.individual-subfield-label {
  font-size: 12px;
  margin: 0;
}

.subfield-formControl {
  /* height: 24px !important; */
}

.form-search-container .form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.dropdown-checkbox {
  font-size: 14px;
}

/********************* END OF FORMS COMPONENT **************/

/********************* ROLES COMPONENT **********************/
.roles-search-bar {
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 16px;
  margin-bottom: 16px;
  color: #858796;
}

.role-delete-button {
  margin-left: 8px;
}

.roles-search-bar-container .form-control {
  margin-left: 8px !important;
}

.roles-search-bar button {
  margin-left: 10px;
}

.roles-search-bar div {
  align-items: center;
  display: flex;
}
.roles-search-bar .form-control {
  width: 300px;
  margin-left: 10px;
}

.editing-role-section {
}

.editing-role-col {
  padding: 0 !important;
}

.editing-role-form-row {
  justify-content: space-between;
  height: max-content !important;
  margin-bottom: 8px;
  position: relative;
}

.editing-role-form-row .form-control {
  margin-left: 10px;
}

.editing-role-form-row .col {
  /* display: flex; */
}

.editing-role-form-col .row {
  width: 100%;
  margin-right: 16px;
}

.editing-role-form-col {
  color: #858796;
}

.roles-info-icon {
  margin-right: 6px;
}

.editing-role-info-col {
  color: #858796;
  margin-left: 8px;
}
/********************* END OF ROLES COMPONENT ***************/

.roles-drag-and-drop-col {
  background: #f8f9fc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 16px !important;
  width: 510px !important;
  color: #858796;
}

.roles-drag-drop-row h5 {
  margin-bottom: 16px;
}

.roles-draggable {
  background: white;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  align-items: center;
}

.roles-draggable:not(:last-child) {
  margin-bottom: 8px;
}

.roles-draggable#hidden {
  display: none;
}

.roles-draggable.disabled {
  background: #e9ecef;
}

.roles-drag-and-drop-context-col {
  height: max-content;
}

.roles-publish-button-row {
  margin-top: 16px;
  justify-content: center;
}

.roles-draggable#drag-disabled {
  background: red;
}

/************* REGISTRATION *************************************/
.registration-icon {
  margin-right: 8px;
  font-size: 18px;
  position: absolute;
}

.registration-success-box {
  background: red;
  width: 600px;
  height: 300px;
}

.registration-icon.pending {
  color: var(--color-special-shadow);
  border-radius: 100px;
}

.registration-checklist-item-row {
}

.registration-checklist-name {
  padding-left: 30px;
}

.registration-checklist-item-row:hover .registration-icon.pending {
  color: var(--color-special) !important;
}

.registration-icon.confirmed {
  color: var(--color-special2);
}

.registration-checklist-item-row:hover .registration-icon.confirmed {
  color: var(--color-special2-hover);
}

.progress-bar-row {
  padding: 8px 0;
}

.registration-progress-bar-container {
  width: 100%;
}

.progress-bar-label {
  color: var(--color-light);
  font-size: 0.65rem;
  font-weight: 800;
}

.registration-progress-bar {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px !important;
}

.registration-progress-bar .progress-bar {
  background: var(--color-special2);
  color: var(--color-main);
}

.registration-form-header {
  justify-content: center;
  margin-bottom: 40px;
  height: max-content !important;
}

.registration-form-header h2 {
  height: max-content;
  font-weight: bold;
}

.registration-body-text {
  justify-content: center;
}

.registration-body-text-footer {
  position: absolute;
  bottom: 0;
  height: max-content !important;
  justify-content: center;
  width: 100%;
}

.registration-body-text-footer .resend-confirmation {
  margin-left: 5px;
}

.registration-column {
  /* height: 100%;
  position: relative;
  display: flex;
  flex-direction: column; */
}

.registration-column-confirmation {
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.registration-item-box {
  background: white;
  font-size: 13px;
  padding: 20px;
}

.registration-confirm-email {
  background: white;
  font-size: 13px;
  padding: 20px;
  /* height: 300px; */
  /* width: 625px; */
}

.registration-complete {
  background: white;
  font-size: 13px;
  padding: 20px;
  min-height: 300px;
}

.registration-row {
}

.registration-confirm-email-container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -70px;
}

.registration-list-item {
  font-size: 0.85rem;
  color: white;
  background: transparent !important;
  padding: 8px 0 !important;
  border: none !important;
  min-height: 36px !important;
}

.registration-list-item.active {
  margin-top: 0 !important;
}

.registration-list-item:hover {
  cursor: pointer;
}

.registration-checklist-item-row {
  width: 100%;
  display: inline-block !important;
}

.registration-list-item.active
  .registration-checklist-item-row
  .registration-icon.pending {
  color: var(--color-special) !important;
}

.registration-list-item.active
  .registration-checklist-item-row
  .registration-icon.confirmed {
  color: MediumSpringGreen !important;
}

.registration-individual-subfield {
  color: var(--color-dark);
  height: max-content !important;
  /* margin-bottom: 16px; */
  /* padding: 16px 8px; */
  padding-bottom: 30px;
  /* border-radius: 300px; */
  /* padding: 50px; */
  /* border: 1px solid var(--color-special); */
}

.registration-individual-subfield .editing-role-form-row {
  width: 100%;
}

.registration-individual-field {
  height: max-content !important;
}

.registration-individual-field .col {
  padding: 0;
}

.registration-individual-field:not(:last-child) {
  margin-bottom: 40px;
}

.registration-form-row-header {
  margin-bottom: 40px;
  height: max-content !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.registration-header {
  width: 100%;
  text-align: center;
  padding-bottom: 8px;
  color: #2f2f40;
  border-bottom: 1px solid var(--color-border);
}

.registration-header h2 {
  font-weight: bold;
}

.registration-form-file .form-control-file {
  background: red !important;
  color: red !important;
}

.registration-submit-button-row {
  height: max-content !important;
  justify-content: center;
  padding-top: 40px;
}

.registration-form-group {
  width: 100%;
}

.registration-branch-subfields {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  height: max-content !important;
}

.registration-branch-column {
}

.registration-branch-row {
  /* background: red; */
  margin-top: -20px !important;
  padding-bottom: 16px;
}

.registration-formControl-column {
  padding: 0 15px !important;
}

.registration-form-control-column {
  display: flex;
  flex-direction: column;
}

.registration-form-file-control {
  width: 100%;
  position: relative;
  z-index: 0 !important;
}

.registration-form-file-control label {
  color: rgba(0, 0, 0, 0.3);
}

.registration-form-control-column .rbt-input-main {
  margin: 0 !important;
  height: 33px;
  border: none;
  font-size: 14px;
}

.registration-form-control-column .rbt-input {
  color: #858796 !important;
}

.registration-form-control-column .rbt-menu a {
  color: #858796 !important;
}

.registration-form-control-column .rbt-close {
  font-weight: normal;
  font-size: 20px;
}

.registration-display-image-cancel-icon {
  font-weight: normal;
  font-size: 20px;
}

.registration-form-control-typeahead {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.registration-form-typeahead-row {
  align-items: center !important;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.registration-form-typeahead-row-invalid {
  align-items: center !important;
  border: 1px solid red;
  border-radius: 4px;
  padding-right: 6px;
}

.login-role-select-div {
  align-items: center;
  display: flex;
}

.register-role-description-icon {
  margin-left: 6px;
}

.registration-display-image-container {
  width: 100%;
  position: relative;
}

.registration-display-image {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
}

.registration-display-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.registration-display-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.registration-signature-canvas {
  background: red;
  width: 100px;
  height: 100px;
}

.registration-sigCanvas-container {
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
}

.registration-sigCanvas {
  background: white;
  width: inherit;
  height: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.registration-sigCanvas:hover {
  cursor: crosshair;
}

.registration-sigCanvas-clear-icon {
  position: absolute;
  bottom: 220px;
  right: 4px;
  font-size: 16px;
}

.registration-sigCanvas-clear-icon:hover {
  cursor: pointer;
  color: black;
}

.registration-sig-image {
  height: 20%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.registration-completed-all-forms {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: black;
}

.registration-completed-all-forms h6 {
  width: 100%;
  text-align: center;
}

.registration-completed-all-forms p {
  text-align: left;
  width: 100%;
  margin: 0;
  font-size: 16px;
  margin: 16px 0 0 0;
  padding: 0;
}

.registration-completed-all-forms .top-logo-row {
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 32px;
}

.registration-completed-all-forms .top-logo-row img {
  width: 150px;
}

.registration-completed-all-forms .bottom-logo-row {
  width: 100%;
  justify-content: center;
  padding-top: 32px;
}

.registration-completed-all-forms .bottom-logo-row img {
  width: 100px;
}

.registration-completed-all-forms .header {
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

.registration-forms-table-box {
  background: white;
  font-size: 13px;
  padding: 20px;
  /* width: 1000px !important; */
  width: 100%;
  height: max-content;
  position: relative;
  /* box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15); */
}

.registration-forms-table-row {
  justify-content: center;
}

.registration-forms-table-col {
  width: 800px !important;
}

.registration-form-row {
  justify-content: center;
}

.registration-iframe {
  /* height: max-content; */
  min-height: 800px;
  height: max-content;
  width: 100%;
}
/************* END OF REGISTRATION ******************************/

/************* SUBMISSIONS **************************************/
.submission-form-control {
  width: 75%;
}

.submission-form-control-label {
  color: #858796;
}

.submission-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.submission-user-selector {
  padding: 0px 18px;
  justify-content: center;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-bottom: 16px;
  color: #858796;
}

.submission-user-selector:not(:last-child) {
  margin-right: 8px;
}

.submission-user-selector:hover {
  background-color: #CF962E;
  border: 1px solid #CF962E;
  color: black;
  cursor: pointer;
}

.submission-user-selector.selected {
  background-color: #CF962E;
  border: 1px solid #CF962E;
  color: white;
}

.submission-user-selector.selected:hover {
  background: #DAAA59 !important;
  border: 1px solid #DAAA59 !important;
  color: black !important;
}

.submissions-search-bar {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.submissions-search-bar label {
  margin: 0;
}

.submissions-search-bar .col:not(:last-child) {
  margin-right: 8px;
}

.submission-form-control {
  color: #858796 !important;
}

.submission-loading-users {
  width: 100%;
  justify-content: center;
}

.submissions-table-box {
  background: white;
  font-size: 13px;
  padding: 20px;
}

.submissions-search {
  margin-bottom: 16px;
}

.submissions-create-button-row {
  justify-content: center;
  padding-top: 16px;
}

.submissions-table-row {
}

.submissions-table {
}

.submissions-table tr {
  color: #858796;
}

.submissions-table th {
}

.submission-subfield-container {
  padding: 4px 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.specific-submission-field-flagged .specific-submission-field-label {
  color: red;
}

.specific-submission-field-flagged .specific-submission-content {
  border: 1px solid red;
  color: red;
}

.specific-submission-field {
  justify-content: space-between;
  align-items: center;
}

.specific-submission-field-label {
  margin: 0;
  font-size: 14px;
}

.specific-submission-editable-container {
  width: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
}

.editable-field {
  margin-left: 4px;
  /* color: #c9cce2; */
  color: rgba(0, 0, 0, 0.3);
  font-style: italic;
}

.saving-edit-user {
  position: absolute;
  right: 60px;
  display: flex;
  align-items: center;
}

.saving-edit-user .spinner-border {
  margin-right: 6px;
}

.specific-submission-content {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 16px;
  width: 100% !important;
  max-width: 100% !important;
  font-size: 14px;
  color: #858796 !important;
  background: rgba(0, 0, 0, 0.03) !important;
}

.specific-submission-content .PhoneInputInput {
  background: transparent;
  border: none;
  color: #858796 !important;
  outline: 0;
}

.specific-submission-field:not(:last-child) {
  margin-bottom: 8px;
}

.admin-review-modal-footer-row {
  justify-content: flex-end;
}

.submission-modal-column {
  margin: 0 !important;
  padding: 0 !important;
}

.submission-modal-column .row {
  align-items: center;
}

.submission-modal-column .row label {
  margin: 0;
  margin-right: 8px;
}

.submission-modal-column .row .col {
  margin-right: 8px;
}

.submission-modal .modal-dialog {
  max-width: 800px;
}

.specific-submission-image {
  max-height: 300px;
  width: 100%;
  object-fit: contain;
}

.user-sub-img-delete-btn {
  position: absolute;
  right: 0;
}

.item-to-delete-confirm {
  position: absolute;
  right: 8px;
  display: flex;
  background: white;
  padding: 4px;
  border-radius: 3px;
}

.item-to-delete-confirm .yes {
  margin-right: 8px;
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
}

.item-to-delete-confirm .yes:hover {
  color: var(--color-special);
}

.item-to-delete-confirm .cancel {
  text-decoration: underline;
  cursor: pointer;
}

.item-to-delete-confirm .cancel:hover {
  color: var(--color-special);
}

.submission-modal-submitted-by {
  font-size: 14px;
}

.submission-modal-submitted-by b {
  margin: 0 4px;
}

.submissions-reset-queries-button {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #858796;
}

.submissions-reset-queries-button:hover {
  color: black;
  cursor: pointer;
}

.identity-mind-tag {
  width: 100%;
  /* margin-bottom: 16px; */
}

.identity-mind-tag .card-header {
  padding: 0;
  border: none;
}

.identity-mind-toggle {
  width: 100%;
  padding: 0 !important;
  height: 100%;
  color: #CF962E !important;
  background-color: transparent !important;
  text-decoration: none !important;
  border-radius: 0 !important;
}

.identity-mind-toggle:hover {
  background: #DAAA59 !important;
  border: 1px solid #DAAA59 !important;
  color: black !important;
  text-decoration: none !important;
  border: 1px solid #CF962E !important;
}

.identity-mind-tag .card-body {
  padding: 8px;
}

.activity-reset-button {
  position: absolute;
  right: 0;
}

.activity-reset-button:hover {
  color: black;
  cursor: pointer;
}

.activity-button-row {
  position: absolute;
  bottom: 8px;
  right: 0;
}

.activity-button-row button:not(:last-child) {
  margin-right: 8px;
}

.activity-tab-quill-row {
  margin-bottom: 8px;
  /* padding-left: 100px; */
}

.field-description-react-quill {
  width: 100%;
  bottom: 48px;
  /* max-height: 150px !important; */
  height: max-content;
  /* padding-left: 100px; */
}

.activity-tab-quill-row .teal-button {
  position: absolute;
  right: 0;
}

.registration-submit-button {
  z-index: 100;
}

.activity-tab-box-row {
  padding: 8px;
  margin-bottom: 24px;
}

.activity-tab-post-button-row {
  justify-content: flex-end;
}

.submissions-email-label {
  position: absolute;
  font-size: 14px;
}

.submissions-email-row {
  margin-bottom: 8px;
}

.submissions-quill-label {
  font-size: 14px;
}

.submissions-email-formControl {
  margin-left: 100px !important;
  color: #858796 !important;
}

.submissions-email-formControl::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.submissions-react-quill {
  width: 100%;
  bottom: 48px;
  /* max-height: 150px !important; */
  height: max-content;
  /* padding-left: 100px; */
}

.activity-individual-submission {
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 8px;
  border-radius: 3px;
}

.activity-individual-submission label {
  font-size: 12px;
  margin: 0;
}

.activity-individual-submission .message {
  font-size: 15px;
  margin-bottom: 4px;
}

.activity-individual-submission .comment {
  font-size: 14px;
}

.edit-specific-user-button {
  position: absolute;
  right: 16px;
  z-index: 1;
}
/************* END OF SUBMISSIONS *******************************/

/************* PREREGISTRATION **********************************/
.prereg-container {
  background-color: white;
  /* height: 100%; */
  justify-content: center;
  display: flex;
  align-items: center;
  padding-top: 95px;
}

.content-div {
  min-height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.prereg-section {
  /* padding-top: 60px; */
  padding-bottom: 60px;
  /* padding: 0 16px 60px 16px; */
  width: 100%;
  justify-content: center;
  display: flex;
  /* height: 100vh; */
  align-items: center;
  /* padding: 95px 0; */
}

.preregistration-starter-box {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 20px 128px;
  background: white;
  margin-top: 80px;
  /* margin-bottom: 64px; */
  /* padding-top: 64px; */
  /* margin-bottom: 64px; */
}

.wrap {
  width: var(--wrap-width);
  max-width: calc(100% - 50px);
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.preregistration-starter-box-row {
  height: 100%;
}

.preregistration-form {
}

.sized-box-380 {
  width: 380px;
}

.centered {
  justify-content: center;
}

.preregistration-content-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.preregistration-content-col h4 {
  color: #858796;
  height: max-content;
}

.prereg-header {
  justify-content: space-around;
  height: max-content;
}

.prereg-form-group {
  /* padding-bottom: 16px; */
  color: var(--color-main);
  /* padding: 0; */
  margin-left: 0;
  margin-right: 0;
}

.prereg-form-group p {
  font-size: 14px;
}

.prereg-h3 {
  font-size: 21px;
  text-align: center;
  color: var(--color-main);
  font-weight: bold;
  margin: 16px 0 0 0;
  /* padding: 0; */
  /* padding-bottom: 25px; */
}

.sub-text {
  color: var(--color-main);
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.center {
  text-align: center;
}

.privacy-statement-p {
  overflow-wrap: break-word !important;
}

.privacy-statement-text {
  margin-left: 4px;
  color: var(--color-special) !important;
  cursor: pointer;
}

.privacy-statement-text:hover {
  color: var(--color-main) !important;
}

.prereg-form-row {
  align-items: center;
}

.prereg-row {
  margin-bottom: 16px;
  width: 100%;
}

.prereg-row#checkbox-row {
  justify-content: center;
}

.prereg-row#checkbox-row #shortbox {
  display: flex;
}

.prereg-row p {
  margin: 0;
}

.flex-start {
  justify-content: flex-start !important;
}

.prereg-row#checkbox-row #shortbox .prereg-radio:not(:last-child) {
  padding-right: 25px !important;
}

.full-width {
  width: 100%;
}

.scrollHolder {
  margin-bottom: 16px;
  border-radius: 15px;
  padding: 15px;
  border: 1px solid var(--color-border);
}

.scrollHolder-content::-webkit-scrollbar {
  width: 10px;
  background: var(--color-light);
  border-radius: 30px;
}

.scrollHolder-content::-webkit-scrollbar-track {
}

.scrollHolder-content::-webkit-scrollbar-thumb {
  background: var(--color-gray);
  border-radius: 30px;
}

.scrollHolder-content {
  overflow-y: scroll;
  height: 120px;
}

.scrollHolder-content p {
  font-size: 12px;
  color: var(--color-main);
  padding-right: 10px;
}

.center-text {
  text-align: center;
}

.padded-text {
}

.prereg-check-box {
  display: block !important;
  background: none !important;
  width: 100% !important;
  text-align: left !important;
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  border: 1px solid var(--color-special) !important;
  color: var(--color-main) !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  height: 46px !important;
  border-radius: 23px !important;
  position: relative !important;
  cursor: pointer !important;
  font-size: 14px !important;
  line-height: 14px !important;
  padding: 15px 0 15px 42px !important;
  -ms-align-items: center !important;
  align-items: center !important;
  display: flex !important;
  text-align: center !important;
  position: relative !important;
}

.prereg-check-box:hover {
  background: var(--color-special) !important;
  color: white !important;
}

.prereg-check-box:hover .new-radio {
  border: 1px solid white;
}

.new-radio {
  /* padding: 0 !important; */
  position: absolute;
  left: 12px;
  top: 12px;
  margin: 0 !important;
  width: 20px;
  height: 20px;
  border-radius: 300px;
  border: 1px solid #999;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
}

.prereg-check-box#checked {
  background: var(--color-special) !important;
  color: white !important;
}

.new-radio {
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
}

.prereg-check-box#checked .new-radio {
  /* background: white; */
  border: 4px solid white;
}

.prereg-form-list {
  margin-top: 8px;
  padding-left: 24px;
}

.prereg-form-list li:not(:last-child) {
  margin-bottom: 4px;
}

.preregistration-form.bottom-form {
  /* padding-bottom: 64px !important; */
  /* margin-bottom: 64px !important; */
}

.prereg-navlink {
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: -6px;
  width: 100%;
  color: #858796;
}

.prereg-navlink a {
  color: #4e73df;
  margin-left: 10px;
}

.prereg-navlink a:hover {
  color: #2e59d9;
}

.prereg-captcha-row {
  justify-content: center;
  margin-bottom: 16px;
}

.prereg-next-button-row {
  justify-content: center;
  margin-bottom: 32px;
}

.cannot-register-row {
  color: #858796;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.cannot-register-navlink {
  justify-content: center;
  position: absolute;
  width: 100%;
  color: #858796;
  display: flex;
  bottom: 0;
}

.cannot-register-navlink a {
  color: #4e73df;
  margin-left: 10px;
}

.cannot-register-navlink a:hover {
  color: #2e59d9;
}

.cannot-register-col {
  height: 100%;
  padding: 0 !important;
}

.confirm-delete-modal-row {
  justify-content: center;
  margin-bottom: 8px;
}

.confirm-delete-modal-row.email-row {
  margin-bottom: 16px;
  font-size: 22px;
}

.confirm-delete-modal-row .teal-button {
  margin-left: 8px;
}

/******************************* USer MANAGEMENT *****************************/

.delete-user {
  margin-left: 8px;
}

.risk-score-accordion-toggle {
  /* margin-left: 16px; */
  color: #858796;
  background: transparent;
  border: none;
  color: #4e73df;
}

.risk-score-accordion-toggle:hover {
  color: #2e59d9;
  text-decoration: underline;
}

.risk-score-accordion-collapse {
  margin-top: 16px;
}

.export-button {
  margin-left: 8px;
  background-color: #CF962E !important;
  border: 1px solid #CF962E !important;
}

.export-button:hover {
  background: #DAAA59 !important;
  border: 1px solid #DAAA59 !important;
  color: black !important;
}

.export-menu {
}

.export-menu a {
  color: #858796;
  font-size: 14px;
}
/************* END OF PREREGISTRATION *****************************/

/***************************** ADMIN MANAGEMENT *******************************/
.admin-container-loading {
  background: red;
  height: 100%;
  justify-content: center;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}
.create-admin-button-row {
  justify-content: flex-end;
  padding-bottom: 16px;
}

.create-admin-form-row {
  margin-bottom: 16px;
}

.create-admin-form-row label {
  margin: 0;
  font-size: 14px;
}

/************************** END ADMIN MANAGEMENT *******************************/

#pdf-to-export {
  /* display: none; */
  color: #858796;
  width: 100%;
}

.pdf-fieldname {
  font-size: 16px;
  color: #858796;
}

.user-sidebar {
  background: var(--color-plum);
  background: linear-gradient(0deg, var(--color-plum), var(--color-plum-dark));
  color: var(--color-main);
}

.sysHead {
  color: var(--color-light);
  font-size: 10px;
  font-weight: 800;
}

.sidebar-navbar-tech {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-title {
  width: 100%;
  word-break: break-all !important;
}

.admin-sidebar#collapsed-sidebar
  .admin-list-accordion-collapse.classify-onboarding {
  position: absolute !important;
  left: 100px;
  background: black !important;
  width: max-content;
  transform: translate(100px, 0px) !important;
  border: none;
}

.admin-sidebar#collapsed-sidebar .dropdown-toggle::after {
  display: none;
}

.admin-sidebar#collapsed-sidebar .admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.admin-sidebar#collapsed-sidebar
  .admin-list-accordion-collapse.classify-onboarding
  ul
  li:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.checkbox-modal-options-row {
  padding-bottom: 16px;
}

.checkbox-modal-options-row:not(:last-child) {
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.checkbox-modal-header-row {
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: baseline;
}

.checkbox-modal-formControl {
  /* margin-bottom: 8px; */
  width: 80% !important;
  color: #858796 !important;
}

.checkbox-subfield-label {
  margin: 0;
  font-size: 14px !important;
}

.checkbox-option-row {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 8px;
}

.checkbox-table {
}

.checkbox-table tr {
  color: #858796;
}

.checkbox-table-th {
  text-align: center;
}

.checkbox-table-tr {
  font-weight: bold;
}

.checkbox-table-td {
  text-align: center;
}

.pages {
  padding: 32px;
}

.pdf-border-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.floating-save-button {
  display: none !important;
  position: absolute;
  right: 4px;
  top: 4px;
}

.pdf-border-box:hover .floating-save-button {
  display: block !important;
  animation: fadeIn ease 1s;
}

.test-image {
  width: 100%;
}

.pdf-header {
  font-weight: bold;
  font-size: 24px !important;
  color: black;
}

.pdf-date-generated {
  /* margin-bottom: 16px; */
  color: grey;
}

.pdf-required {
  font-style: italic;
  font-size: 14px;
  color: grey;
}

.pdf-header-content {
  margin-bottom: 16px;
}

.pdf-item {
  margin-bottom: 16px;
}

#document .pdf-item .title {
  border-bottom: 1px solid black;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
  color: black;
}

#document .pdf-item .field {
  /* padding: 8px; */
  margin-bottom: 8px;
}

#document .pdf-item .fieldname {
  font-weight: bold;
  margin-bottom: 4px;
  color: black;
}

#document .pdf-item .answer {
  border: 1px solid lightgrey;
  padding: 8px;
  border-radius: 3px;
  color: grey;
}

#document .pdf-item .image {
  border: 1px solid lightgrey;
}

#table .pdf-item .title {
  background: rgba(64, 125, 180);
  color: white;
  padding: 4px;
  font-weight: bold;
  font-size: 22px;
}

#table .pdf-item .field {
  padding: 8px;
  display: flex;
  background: rgba(245, 245, 245);
}

#table .pdf-item .field:nth-child(even) {
  background: white !important;
}

#table .pdf-item .fieldname {
  color: black;
  width: 50%;
  padding-right: 8px;
}

#table .pdf-item .answer {
  width: 50%;
  padding-left: 8px;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  color: grey !important;
}

#table .pdf-item .image {
  width: 50%;
}

#table .pdf-item .branch {
  width: 100%;
}

.pdf-item #hidden {
  display: none !important;
}

.pdf-date-generated .username {
  text-decoration: underline;
  padding: 0 4px;
  color: black;
}

.pdf-item .image {
  max-width: 100%;
  height: 200px;
  object-fit: contain !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 3px;
}

.toggle-for-user-info-modal {
  display: flex;
  justify-content: flex-end;
  /* margin-bottom: 8px; */
  width: 100%;
  height: 25px;
  align-items: center;
}

.toggle-for-user-info-modal button:not(:last-child) {
  margin-right: 4px;
}

.floating-save-button {
}

.contact-us-widget-container {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.pp-verification-col {
  justify-content: center;
  display: flex;
  align-items: center;
}

.passport-verification-img {
  width: 100%;
  max-width: 600px;
}

.reg-ul {
  padding: 0;
  padding-left: 16px;
}

.bold-li {
  list-style-type: none;
  font-weight: bold;
  margin-left: -16px;
}

.registration-welcome-section {
  margin-bottom: 32px;
}

.registration-welcome-header {
  height: max-content;
  font-weight: bold;
  margin-bottom: 32px;
  color: var(--color-main);
  font-size: 28px;
}

.registration-welcome-text {
  font-size: 16px;
  height: max-content;
  /* display: inline-block !important; */
  /* margin-bottom: 16px; */
}

.registration-welcome-text .resend-confirmation {
  margin-left: 6px;
}

.registration-welcome-subheader {
  color: var(--color-special);
  font-size: 18px;
  font-weight: bold;
}

.registration-welcome-list {
  font-size: 16px;
  padding: 0;
  padding: 0 16px;
  list-style: none;
  counter-reset: li;
}

.registration-welcome-list-item {
  margin-bottom: 8px;
}

.registration-welcome-list-item::before {
  content: counter(li) ". ";
  color: var(--color-special);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  margin-right: 1px;
  counter-increment: li;
  font-weight: bold;
}

.registration-list-body {
  height: max-content !important;
  margin-bottom: 32px;
}

.registration-list-body .col {
}

.pinkText {
  color: var(--color-special);
}

.pp-row {
  justify-content: center;
}

.reg-nav {
  padding: 16px !important;
}

.reg-nav button {
  color: red !important;
}
.reg-nav .admin-logo {
  width: 224px;
}

.reg-content-pane {
  /* height: calc(100vh - 70px); */
  /* overflow-y: scroll; */
  z-index: 2;
  position: relative;
  /* margin-top: 84px; */
  /* padding-top: 120px; */
  /* padding-bottom: 120px; */
  padding: 36px 0;
}

.superNoPadding {
  padding: 0 !important;
}

.navbar-title-row {
  color: var(--color-light);
}

.nav-logout-btn-dark {
  cursor: pointer;
  color: var(--color-dark);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10000;
}

.nav-logout-btn-light {
  cursor: pointer;
  color: var(--color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10000;
}

.nav-logout-btn-dark svg,
.nav-logout-btn-light svg {
  margin-right: 4px;
}

.nav-logout-btn-light:hover,
.nav-logout-btn-dark:hover {
  text-decoration: underline;
  color: var(--color-special);
}

.ql-content {
  text-align: left;
  align-items: left;
}

.ql-content img {
  max-width: 100% !important;
}

.ql-content p {
  margin: 8px;
}

.ql-align-left {
  text-align: left;
  align-items: left;
}

.ql-align-center {
  text-align: center !important;
  align-items: center !important;
}

.ql-align-right {
  text-align: right;
  align-items: right;
}

.ql-align-justify {
  text-align: justify;
  align-items: justify;
}

.reg-responsive-topbar {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .reg-responsive-sidebar {
    display: none;
  }

  .nav-logout-btn-big-screens {
    display: none;
  }

  .reg-responsive-topbar {
    display: block;
  }

  .reg-content-pane {
    padding: 120px 0 36px 0;
  }
}

@media only screen and (min-width: 1100px) {
  .reg-responsive-topbar {
    display: none !important;
  }
}

.idm-spinner {
  margin-right: 4px;
}

.csv-link {
  color: white !important;
}

.csv-link:hover {
  text-decoration: none !important;
  color: var(--color-special2) !important;
}

.csv-refresh {
}

.csv-refresh:hover {
  color: var(--color-special2);
}
